<template>
    <div class="greyBK">
        <b-container>
            <a :href="'/partners'" class="backBTN">
                <h4 class="title">Partners</h4>
            </a>
            <!-- <a :href="'/the-papwa-swegolum-class'" class="backBTN">
                Back To The Papwa Swegolum Class
            </a> -->
            <b-row class="partnerBlock">
                <b-col cols="12" lg="4" md="4" sm="12">
                    <b-img class="partnerImage" :src="partner[0].acf.white_logo"></b-img>
                </b-col>
                <b-col cols="12" lg="8" md="8" sm="12">
                    <h2 class="partnerTitle" v-html="partner[0].title.rendered"></h2>
                    <p class="partnerDescript" v-html="partner[0].content.rendered"></p>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import axios from 'axios';
export default {
  name: "partner",
  props: ["slug", "dis"],
    data() {
        return {
        partner: [],
        partnerTitle: ''
        };
    },
    methods: {
        getSponsor() {
            let sponsors = 
                process.env.VUE_APP_WP_ASIAN_TOUR_API_URL +
                "wp/v2/sponsors?slug=" +
                this.slug + "&randomadd=" +
                new Date().getTime()
                axios.get(sponsors).then(response => {
                this.partner = response.data;
        });
        },
    },
    mounted() {
        this.getSponsor()
    },
    metaInfo() {
        return {
            title: this.partnerTitle,
            meta: [
                { charset: 'utf-8' },
                { name: 'description', content: this.description },
            ]
        }
    },
};
</script>
<style scoped>
    ::v-deep .footer {
        background-color: #0A3F7F;
        color: white;
        padding-top: 3%;
        padding-left: 2%;
        padding-bottom: 3%;
        position: absolute;
        bottom: 0;
        width: 100%;
    }
    .greyBK {
        background-color: #F5F5F5;
        padding-top: 10px;
        padding-bottom: 60px;
        height: 80vh!important;
    }
    a.backBTN:hover > .title {
        color: #41a2f6;
        text-decoration: none;
    }
    a.backBTN:hover {
        color: #41a2f6;
        text-decoration: none;
    }
    .title {
        color: #0A3F7F;
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 30px;
        margin-top: 50px;
    }
    .partnerTitle {
        color: #0A3F7F;
        font-size: 20px;
        font-weight: 500;
        text-transform: capitalize;
        /* padding-bottom: 25px;
        padding-top: 25px; */
       
    }
    p.partnerDescript {
        font-size: 14px;
        font-weight: 400;
        color: #707070;
    }
    .partnerBlock {
        margin-top: 50px;
    }
    .partnerImage {
        width: 100%;
    }
    @media only screen and (max-width: 500px) {
        .partnerTitle {
            margin-top: 50px;
            margin-bottom: 30px;
        }
        .greyBK {
            background-color: #F5F5F5;
            padding-top: 10px;
            padding-bottom: 60px;
            height: auto!important;
        }
    }
</style>